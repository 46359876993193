const FAQ = () => {
  return (
    <>
      <div className="flex flex-col gap-5 p-5">
        <div className="flex items-center gap-2 text-primary text-3xl font-semibold">FAQ's</div>
        <div className="w-full p-5 bg-white rounded-lg flex flex-col gap-5">

        </div>
      </div>
    </>
  )
}

export default FAQ
